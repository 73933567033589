import * as Yup from 'yup';

const validation = Yup.object({
  serologyTests: Yup.array().of(
    Yup.object({
      name: Yup.string().required(),
      testRun: Yup.boolean().required(),
      status: Yup.string().when('testRun', {
        is: true,
        then: Yup.string().required(),
        otherwise: Yup.string().nullable(),
      }),
    }),
  ),
});

export default validation;
